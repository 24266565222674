<template>
    <div id="login" class="login-organization" :class="$mq">
        <template v-if="normalLogin">
            <div class="login-top">
                <div class="help" @click="openTicketPopup()"></div>
                <div class="lang">
                    <!-- <button v-for="lang in languages" :key="lang" class="btn" :class="{ selected: lang == language }" @click="changeLang(lang)">{{ lang }}</button> -->
                    <select :value="language" :v-model="language" @change="language = $event.target.value">
                        <option v-for="lang in languages" :value="lang" :data="lang" :key="lang">{{ lang }}</option>
                    </select>
                </div>
            </div>

            <!-- Main login -->
            <template v-if="selectedView == 'login-main'">
                <div class="login-container">
                    <div class="logo"></div>
                    <div class="login-gallery">
                        <div class="gallery-image"></div>
                        <div class="gallery-info">
                            <vue-typed-js :key="languageChangedKey + '.title'" :strings="[$t('login.wizard.title')]" :loop="false" :typeSpeed="2" :startDelay="0" :showCursor="false">
                                <span class="titl typing"></span>
                            </vue-typed-js>
                            <template v-if="['landscape', 'desktop'].includes($mq)">
                                <vue-typed-js :key="languageChangedKey + '.message'" :strings="[ $t('login.wizard.message') ]" :loop="false" :typeSpeed="2" :startDelay="4" :showCursor="false">
                                    <span class="description typing"></span>
                                </vue-typed-js>
                            </template>
                        </div>
                    </div>
                    <div class="login-form">
                        <div class="logo"></div>
                        <div class="switch-logins">
                            <span @click="loginType = 'local', auditLogin = false" :class="{'selected': loginType === 'local'}" class="btn">{{$t('login.access_type.local')}}</span>
                            <span @click="loginType = 'auditor', auditLogin = true" :class="{'selected': loginType === 'auditor'}" class="btn">{{$t('login.access_type.auditor')}}</span>
                        </div>
                         <!-- Local login -->
                        <div class="form" v-if="loginType === 'local'">
                            <div class="error-message" v-show="showError" v-html="errorMessage" @click="errorMessage === $t('login.errors.access') ? gotoForgotPass() : true"></div>

                            <div class="input-container">
                                <template v-if="['portrait'].includes($mq)">
                                    <input class="input" type="text" v-model.trim="loginData.location" @blur="isFocusInput = false" @focus="scrollTo($event.target)" :placeholder="$t('login.form.location')" />
                                </template>
                                <template v-if="['landscape', 'desktop'].includes($mq)">
                                    <label class="label">{{ $t('login.form.location') }}</label>
                                    <input class="input" @keyup.enter="doLogin(false)" type="text" v-model.trim="loginData.location" spellcheck="false" :placeholder="$t('login.form.location_placeholder')" />
                                </template>
                            </div>
                            <div class="input-container">
                                <template v-if="['portrait'].includes($mq)">
                                    <input class="input" inputmode="numeric" type="password" @blur="isFocusInput = false" @focus="scrollTo($event.target)" v-model.trim="loginData.password" @keyup.enter="doLogin(false)" :placeholder="$t('login.form.password')" />
                                </template>
                                <template v-if="['landscape', 'desktop'].includes($mq)">
                                    <label class="label" for="password">{{ $t('login.form.password') }}</label>
                                    <input class="input" inputmode="numeric" type="password" v-model.trim="loginData.password" @keyup.enter="doLogin(false)" :placeholder="$t('login.form.password_placeholder')" />
                                </template>
                            </div>
                        </div>
                        <!-- Auditor login -->
                        <div class="form" v-else-if="loginType ==='auditor'">
                            <div class="error-message" v-show="showError" v-html="errorMessage" @click="errorMessage === $t('login.errors.auditor_access') ? gotoForgotPass() : true"></div>
                            <div class="input-container">
                                <template v-if="['portrait'].includes($mq)">
                                    <input class="input" type="text" v-model.trim="loginData.email" @blur="isFocusInput = false" @focus="scrollTo($event.target)" :placeholder="$t('login.form.location_auditor')" />
                                </template>
                                <template v-if="['landscape', 'desktop'].includes($mq)">
                                    <label class="label">{{ $t('login.form.location_auditor') }}</label>
                                    <input class="input" @keyup.enter="doAuditorLogin()" type="text" v-model.trim="loginData.email" spellcheck="false" :placeholder="$t('login.form.location_auditor_placeholder')" />
                                </template>
                            </div>
                            <div class="input-container">
                                <template v-if="['portrait'].includes($mq)">
                                    <input class="input" type="password" @blur="isFocusInput = false" @focus="scrollTo($event.target)" v-model.trim="loginData.password" @keyup.enter="doAuditorLogin()" :placeholder="$t('login.form.password_auditor')" />
                                </template>
                                <template v-if="['landscape', 'desktop'].includes($mq)">
                                    <label class="label" for="password">{{ $t('login.form.password_auditor') }}</label>
                                    <input class="input" type="password" v-model.trim="loginData.password" @keyup.enter="doAuditorLogin()" :placeholder="$t('login.form.password_auditor')" />
                                </template>
                            </div>
                        </div>
                        <!-- Local login btn-->
                        <template v-if="loginType==='local'">
                            <template v-if="['portrait'].includes($mq)">
                                <button class="btn submit-login" :class="{ loading: loading }" v-on:click.stop="doLogin(false)">{{ $t('login.actions.login') }}</button>
                                <div class="terms-conditions" v-html="$t('login.terms_conditions')"></div>
                                <div class="lost-password" @click="gotoForgotPass">{{ $t('login.pw_forgot.access_text') }}</div>
                                <div class="lost-password" v-if="Object.values(storedLocations).length > 0" @click="goToSiteLogin()">{{ $t('login.pw_forgot.multisite_text') }}</div>
                            </template>
                            <template v-else>
                                <button class="btn submit-login" :class="{ loading: loading }" v-on:click.stop="doLogin(false)">{{ $t('login.actions.login') }}</button>
                                <div class="terms-conditions" v-html="$t('login.terms_conditions')"></div>
                                <div class="lost-password" @click="gotoForgotPass">{{ $t('login.pw_forgot.access_text') }}</div>
                                <div class="lost-password landscape" @click="goToSiteLogin()" v-if="Object.values(storedLocations).length > 0">{{ $t('login.pw_forgot.multisite_text') }}</div>
                            </template>
                        </template>
                        <!-- Auditor login btn-->
                        <template v-else-if="loginType==='auditor'">
                            <template v-if="['portrait'].includes($mq)">
                                <button class="btn submit-login" :class="{ loading: loading }" v-on:click.stop="doAuditorLogin()">{{ $t('login.actions.login') }}</button>
                                <div class="terms-conditions" v-html="$t('login.terms_conditions')"></div>
                                <div class="lost-password" @click="gotoForgotPass">{{ $t('login.pw_forgot.access_text') }}</div> <!-- Cambiar -->
                            </template>
                            <template v-else>
                                <button class="btn submit-login" :class="{ loading: loading }" v-on:click.stop="doAuditorLogin()">{{ $t('login.actions.login') }}</button>
                                <div class="terms-conditions" v-html="$t('login.terms_conditions')"></div>
                                <div class="lost-password" @click="gotoForgotPass">{{ $t('login.pw_forgot.access_text') }}</div> <!-- Cambiar -->
                            </template>
                        </template>
                    </div>
                </div>
            </template>

            <!-- Forgot password - Send location user/email -->
            <template v-if="selectedView == 'password-forgot'">
                <div class="login-container wide forgot-password" :class="{ 'no-connection': !hasConnection }">
                    <div class="logo" @click="goBack()"></div>
                    <div class="login-form">
                        <div class="logo" v-if="!isFocusInput"></div>
                        <template v-if="['landscape', 'desktop'].includes($mq)">
                            <div class="login-return" @click="goBack()">{{ $t('login.go_back') }}</div>
                        </template>

                        <div class="error-message" v-if="!hasConnection">{{ $t('login.error_connection') }}</div>

                        <div class="form">
                            <Message v-if="pwForgot.error.mail" :message="$t('login.pw_forgot.error_mail_msg')" type="error" :buttonLabel="$t('login.pw_forgot.error_mail_action')" @buttonAction="gotoHelpcenter()"></Message>

                            <div class="titl" v-html="$t('login.pw_forgot.title')"></div>
                            <div class="form-description" v-html="$t('login.pw_forgot.description')"></div>

                            <div class="error-message" v-if="pwForgot.error.validate">{{ $t('login.pw_forgot.error_msg') }}</div>

                            <div class="input-container">
                                <template v-if="['portrait'].includes($mq)">
                                    <input class="input" type="text" v-model.trim="pwForgot.input" @blur="isFocusInput = false" @focus="scrollTo($event.target)" @keyup.enter="pwForgotPass" :placeholder="$t('login.pw_forgot.input_label')" />
                                </template>
                                <template v-if="['landscape', 'desktop'].includes($mq)">
                                    <label class="label">{{ $t('login.pw_forgot.input_label') }}</label>
                                    <input class="input" type="text" v-model.trim="pwForgot.input" @keyup.enter="pwForgotPass" />
                                </template>
                            </div>
                        </div>

                        <button class="btn submit-login" :class="{ loading: loading, disabled: !hasConnection }" v-on:click.stop="pwForgotPass">{{ $t('login.pw_forgot.action') }}</button>
                        <template v-if="['portrait'].includes($mq)">
                            <div class="return-login" @click="goBack()">{{ $t('login.go_back') }}</div>
                            <!-- <div class="login-return" @click="goBack()">{{ $t('login.go_back') }}</div> -->
                        </template>
                    </div>
                </div>
            </template>

            <!-- Forgot password - Send confirmation CODE -->
            <template v-if="selectedView == 'password-code'">
                <div class="login-container wide set-code" :class="{ 'no-connection': !hasConnection }">
                    <div class="logo" @click="goBack()"></div>
                    <div class="login-form">
                        <div class="logo"></div>

                        <div class="error-message" v-if="!hasConnection">{{ $t('login.error_connection') }}</div>

                        <div class="form">
                            <div class="login-return" @click="goBack()">{{ $t('login.go_back') }}</div>
                            <div class="titl" v-html="$t('login.pw_code.title')"></div>
                            <div class="form-description" v-html="$t('login.pw_code.description', { s: pwForgot.location_email })"></div>

                            <div class="success-message" v-if="pwCode.success.resend">{{ $t('login.pw_code.success_resend_msg') }}</div>
                            <div class="error-message" v-if="pwCode.error.validate">{{ $t('login.pw_code.error_msg') }}</div>
                            <div class="error-message" v-if="pwCode.error.resend">{{ $t('login.pw_code.error_resend_msg') }}</div>
                            <div class="error-message" v-if="pwCode.error.invalid">{{ $t('login.pw_code.error_invalid_msg') }}</div>

                            <div class="input-container">
                                <template v-if="['landscape', 'desktop'].includes($mq)">
                                    <label class="label">{{ $t('login.pw_code.input_label') }}</label>
                                </template>
                                <input class="input" inputmode="numeric" type="number" v-model.trim="pwCode.input" :placeholder="$t('login.pw_code.input_placeholder')" @keyup="truncateCode" @keyup.enter="pwSetCode" max="999999" />
                            </div>
                        </div>

                        <template v-if="['portrait'].includes($mq)">
                            <button class="btn submit-login" :class="{ loading: loading, disabled: !hasConnection }" v-on:click.stop="pwSetCode">{{ $t('login.pw_code.action') }}</button>
                            <div class="resend-code" :class="{ disabled: pwCode.resended }" @click="resendCode">{{ $t('login.pw_code.resend_code') }}</div>
                        </template>
                        <template v-if="['landscape', 'desktop'].includes($mq)">
                            <div class="resend-code" :class="{ disabled: pwCode.resended }" @click="resendCode">{{ $t('login.pw_code.resend_code') }}</div>
                            <button class="btn submit-login" :class="{ loading: loading, disabled: !hasConnection }" v-on:click.stop="pwSetCode">{{ $t('login.pw_code.action') }}</button>
                        </template>
                    </div>
                </div>
            </template>

            <!-- Forgot password - RESET Password -->
            <template v-if="selectedView == 'password-reset'">
                <div class="login-container wide reset-password" :class="{ 'no-connection': !hasConnection }">
                    <div class="logo" @click="goBack()"></div>

                    <div class="login-form">
                        <div class="logo"></div>

                        <div class="error-message" v-if="!hasConnection">{{ $t('login.error_connection') }}</div>

                        <div class="form">
                            <div class="login-return" @click="goBack()">{{ $t('login.go_back') }}</div>
                            <div class="titl" v-html="$t('login.pw_reset.title')"></div>
                            <div class="form-description" v-html="$t('login.pw_reset.description')"></div>

                            <div class="error-message" v-if="pwReset.error.format">{{ $t('login.pw_reset.error_format_msg') }}</div>
                            <div class="error-message" v-if="pwReset.error.samePass">{{ $t('login.pw_reset.error_same_pass_msg') }}</div>
                            <div class="error-message" v-if="pwReset.error.isNotNumber">{{ $t('login.pw_reset.error_not_number_msg') }}</div>

                            <div class="input-container">
                                <template v-if="['landscape', 'desktop'].includes($mq)">
                                    <label class="label">{{ $t('login.pw_reset.password_label') }}</label>
                                </template>
                                <input class="input" pattern="[0-9]*" inputmode="numeric" type="password" @focus="scrollTo($event.target)" v-model.trim="pwReset.password" :placeholder="$t('login.pw_reset.password_label')" @blur="checkPass(), (isFocusInput = false)" />
                            </div>
                            <div class="input-container">
                                <template v-if="['landscape', 'desktop'].includes($mq)">
                                    <label class="label">{{ $t('login.pw_reset.password_confirm_label') }}</label>
                                </template>
                                <input class="input" inputmode="numeric" type="password" @focus="scrollTo($event.target)" v-model.trim="pwReset.passwordConfirm" :placeholder="$t('login.pw_reset.password_confirm_label')" @blur="checkPass(), (isFocusInput = false)" />
                            </div>
                        </div>

                        <button class="btn submit-login" :class="{ loading: loading }" v-on:click.stop="pwResetPassword">{{ $t('login.pw_reset.action') }}</button>
                    </div>
                </div>
            </template>

            <!-- Forgot Password Auditor -->
            <template v-if="selectedView == 'password-forgot-auditor'">
                <div class="login-container wide forgot-password" :class="{ 'no-connection': !hasConnection}">
                    <div class="logo" @click="goBack()"></div>
                    <div class="login-form">
                        <div class="logo" v-if="!isFocusInput"></div>
                        <template>
                            <div class="login-return" @click="goBack()">{{ $t('login.go_back') }}</div>
                        </template>

                        <div class="error-message" v-if="!hasConnection">{{ $t('login.error_connection') }}</div>

                        <div class="form">
                            <Message v-if="pwForgot.error.mail" :message="$t('login.pw_forgot.error_mail_msg')" type="error" :buttonLabel="$t('login.pw_forgot.error_mail_action')" @buttonAction="gotoHelpcenter()"></Message>

                            <div class="titl" v-html="$t('login.pw_auditor.title')"></div>
                            <div class="form-description" v-html="$t('login.pw_auditor.description')"></div>

                            <div class="input-container">
                                <template v-if="['portrait'].includes($mq)">
                                    <input class="input" type="text" v-model.trim="forgotEmailSubmit" @blur="isFocusInput = false" @focus="scrollTo($event.target)" @keyup.enter="forgotPassSubmit" :placeholder="$t('login.pw_auditor.input_label')" />
                                </template>
                                <template v-if="['landscape', 'desktop'].includes($mq)">
                                    <label class="label">{{ $t('login.pw_auditor.input_label') }}</label>
                                    <input class="input" type="text" v-model.trim="forgotEmailSubmit" @keyup.enter="forgotPassSubmit" />
                                </template>
                            </div>
                            <div class="auditor-messages">
                                <div class="success-message" v-show="emailSend">{{$t('login.pw_auditor.send_email')}}</div>
                                <div class="error-message" v-show="errorMail">{{ $t('login.pw_auditor.error') }}</div>
                            </div>
                        </div>
                        <button class="btn submit-login" :class="{ loading: loading, disabled: !hasConnection }" v-on:click.stop="forgotPassSubmit">{{ $t('login.pw_forgot.action') }}</button>
                        <template v-if="['portrait'].includes($mq)">
                            <div class="return-login" @click="goBack()">{{ $t('login.go_back') }}</div>
                        </template>
                    </div>
                </div>
            </template>
            <div class="login-bottom" v-if="!isFocusInput || ['landscape', 'desktop'].includes($mq)">
                <Copyright customClass="login-copyright"></Copyright>
                <div class="version">{{ $t('tooltip.version') }}: {{ appVersion }}</div>
            </div>
        </template>

        <!-- Multiaccount login -->
        <template v-else>
            <div class="multiaccount-login">
                <div class="login-top">
                    <div class="help" @click="openTicketPopup()"></div>
                    <div class="lang">
                        <!-- <button v-for="lang in languages" :key="lang" class="btn" :class="{ selected: lang == language }" @click="changeLang(lang)">{{ lang }}</button> -->
                        <select :value="language" :v-model="language" @change="language = $event.target.value">
                            <option v-for="lang in languages" :value="lang" :data="lang" :key="lang">{{ lang }}</option>
                        </select>
                    </div>
                </div>
                <MultiSite @removeSite="removeStoredSite" @loginSite="loginWithSite" @goToNormalLogin="changeLogin" :sites="storedLocations"></MultiSite>
            </div>
        </template>
    </div>
</template>

<script>
import MultiSite from '@/components/domain/login/multiSite'
import Vue from 'vue'
import VueTypedJs from 'vue-typed-js'
import localforage from 'localforage'
import Copyright from '@/components/layout/Copyright'
import Message from '@/components/domain/ui/Message.vue'

Vue.use(VueTypedJs)

export default {
    name: 'Login',
    components: { Copyright, Message, MultiSite },

    data() {
        return {
            showError: false,
            errorMessage: '',
            loginData: {
                email: '',
                location: '',
                password: ''
            },
            loading: false,

            pwForgot: {
                active: false,
                error: {
                    validate: false,
                    mail: false
                },
                input: '',
                location_id: '',
                location_email: ''
            },
            pwCode: {
                active: false,
                error: {
                    validate: false,
                    resend: false,
                    invalid: false
                },
                success: {
                    resend: false
                },
                input: '',
                resended: ''
            },
            pwReset: {
                active: false,
                error: {
                    format: false,
                    samePass: false,
                    isNotNumber: false
                },
                password: '',
                passwordConfirm: ''
            },
            storedLocations: [],
            // PORTRAIT
            isFocusInput: false,
            normalLogin: false,
            auditLogin:false,
            loginType: "local",
            forgotEmailSubmit: '',
            emptyEmail: false,
            emailSend: false,
            errorMail: false,
            languageChangedKey: 0
        }
    },
    computed: {
        selectedView() {
            let result = 'login-main'

            result = this.pwForgot.active ? 'password-forgot' : result
            result = this.pwForgot.active && this.auditLogin ? 'password-forgot-auditor' : result
            result = this.pwCode.active ? 'password-code' : result
            result = this.pwReset.active ? 'password-reset' : result
            return result
        },

        language: {
            get() {
                var lang = this.$store.getters['getLanguage']
                return lang && lang != 'undefined' ? this.$store.getters['getLanguage'] : 'es'
            },
            set(val) {
                log(val)
                this.$store.commit('changeLanguage', val)
            }
        },

        languages() {
            return this.$store.getters['getLanguages']
        },
        appVersion() {
            return process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : ''
        },
        hasConnection() {
            return fnCheckConnection()
        },
        isApp() {
            return this.$store.getters['getIsApp']
        }
    },
    methods: {
        goToSiteLogin() {
            this.normalLogin = false
        },
        changeLogin() {
            this.showError = false
            this.normalLogin = true
        },
        loginWithSite(location) {
            this.loginData.location = location.username
            this.loginData.password = window.atob(location.password)
            this.doLogin(true)
        },
        /* loginWithSiteAuditor(location) {
            const self = this
            this.$store.dispatch('loginUser/doLoginAuditor', {
                mq: self.$mq,
                location_id: location.location_id,
            }).then(function (response) {
                const config = self.$store.getters['login/getConfig']
                const path = config && config.home ? config.home : false
                log('PATH', path)
                self.$store.commit('setCustomHome', path)
                self.$store.commit('setAuditorLogin', true)
                self.$router.push(self.$root.redirectPath(!path ? '/home' : path))
            })
        }, */
        // changeLang(lang) {
        //     this.$store.commit('changeLanguage', lang)
        // },

        async scrollTo(element) {
            const delay = (ms) => new Promise((res) => setTimeout(res, ms))
            // console.log("Branch")
            // console.log("Branch2")
            this.isFocusInput = true
            const scrollElement = async () => {
                if (['portrait'].includes(this.$mq)) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
                    await delay(750)
                    window.removeEventListener('resize', scrollElement)
                }
            }
            window.addEventListener('resize', scrollElement)
        },

        doLogin2(isLoginSite, username, password){
            var self = this
            this.$store
                .dispatch('login/doLogin', {
                    username: username,
                    password: isLoginSite ? password : sha256(password)
                })
                .then(function (response) {
                    if (response) {
                        localforage.config({
                            name: 'alexdb',
                            version: 1.0
                        })
                        var db_products = localforage.createInstance({
                            name: 'alexdb',
                            storeName: 'products'
                        })
                        db_products.clear()
                        var db_product_categories = localforage.createInstance({
                            name: 'alexdb',
                            storeName: 'product_categories'
                        })
                        db_product_categories.clear()
                        var db_employee = localforage.createInstance({
                            name: 'alexdb',
                            storeName: 'employee'
                        })
                        db_employee.clear()

                        log('doLogin3')

                        localforage
                            .clear()
                            .then(function () {
                                log('Database is now empty.')

                                // self.$store.dispatch( 'checkChangesPrint', response.data.printer );
                                const config = self.$store.getters['login/getConfig']

                                var path = config && config.home ? config.home : false
                                log('PATH', path)
                                self.$store.commit('setCustomHome', path)

                                // self.$store
                                //     .dispatch('employee/loadEmployees')
                                //     .then(function() {
                                //         log(self.$store.getters['employee/getAllEmployees'])
                                //         self.$store.dispatch('employee/loadRoles').then(function() {})
                                //         //   self.$store.dispatch("setPrinterModels");
                                //     })
                                //     .then(function() {
                                self.$router.push(self.$root.redirectPath(!path ? '/home' : path))
                                // })
                            })
                            .catch(function (err) {
                                log(err)
                            })
                    } else {
                        const blackListData = self.$store.getters['login/getBlackListData']
                        let errorMessage = self.$t('login.errors.access')

                        if (blackListData.warning) {
                            errorMessage = self.$t('login.errors.blacklist.warning')
                        }

                        if (blackListData.blocked) {
                            errorMessage = self.$t('login.errors.blacklist.blocked')
                        }

                        const wrong_ip = self.$store.getters['login/getWrongIp']
                        if (wrong_ip) {
                            errorMessage = self.$t('login.errors.ip_out_of_range')
                        }

                        self.showError = true
                        self.errorMessage = errorMessage
                        self.loading = false
                        if (isLoginSite) {
                            self.$popup.alert({
                                message: errorMessage,
                                closable: true,
                                blocking: false,
                                textSave: self.$t('popup.audits.exit'),
                                callSave: function (response) {
                                    self.$popup.close()
                                    self.loginData.location = ''
                                    self.loginData.password = ''
                                }
                            })
                        }
                    }
                })
                .catch((error) => {
                    self.showError = true
                    self.errorMessage = self.$t('login.errors.offline')
                    self.loading = false
                })
        },

        doLogin: async function (isLoginSite) {
            var self = this
            self.showError = false
            self.loading = true
            var validation = []
            var username = self.loginData.location
            var password = self.loginData.password

            if (username == '' || username.length <= 0) validation.push(false)
            if (password == '' || password.length <= 0) validation.push(false)

            if (validation.length === 0) {
                if (this.hasConnection) {
                    this.$overlay.loading()
                    console.warn('init syncronize')
                    try {
                        await this.$store.dispatch('syncronize')
                        console.warn('end syncronize')
                        this.$overlay.hide()

                        this.doLogin2(isLoginSite, username, password)
                    } catch (error) {
                        self.$overlay.hide()
                        let changesToSyncronize = false
                        self.$store.dispatch('getCountSyncItems').then((count) => {
                            changesToSyncronize = count
                            if (count > 0) {
                                self.$popup.confirm({
                                    message: self.$tc('config.changes_sync') + ' ' + self.$tc('config.changes_sync_logout', changesToSyncronize, { number: changesToSyncronize }) + ' ' + self.$tc('config.changes_sync_logout2', changesToSyncronize) + ' ' + self.$tc('config.changes_sync_login3'),
                                    // title: self.$t('popup.confirm.title'),
                                    textSave: self.$t('login.actions.try_again'),
                                    textCancel: self.$t('login.actions.remove_data'),
                                    callSave: function () {
                                        self.$popup.close()
                                    },
                                    callCancel: async function () {
                                        await self.$popup.close()
                                        self.$popup.confirm({
                                            message: self.$t('login.remove_data_confirm_msg'),
                                            textSave: self.$t('login.actions.cancel'),
                                            textCancel: self.$t('login.actions.remove_data_confirm'),
                                            callSave: function () {
                                                self.$popup.close()
                                            },
                                            callCancel: function () {
                                                self.$popup.close()
                                                self.$store.dispatch('login/cleanOfflineDatabase').then(function () {
                                                    self.doLogin(isLoginSite)
                                                })
                                            }
                                        })
                                    }
                                })
                            } else {
                                // self.showError = true
                                // self.errorMessage = self.$t('login.errors.offline')
                                // self.loading = false
                                self.$store.dispatch('login/cleanOfflineDatabase').then(function () {
                                    self.doLogin2(isLoginSite,username,password)
                                })
                            }
                        })
                    }
                } else {
                    self.showError = true
                    self.errorMessage = self.$t('login.errors.offline')
                    self.loading = false
                }
            } else {
                self.showError = true
                self.errorMessage = self.$t('login.errors.input')
                self.loading = false

                if (isLoginSite) {
                    self.$popup.alert({
                        message: self.$t('login.error_access'),
                        closable: true,
                        blocking: false,
                        textSave: self.$t('popup.audits.exit'),
                        callSave: function (response) {
                            self.$popup.close()
                            self.loginData.location = ''
                            self.loginData.password = ''
                        }
                    })
                }
            }
        },
        doAuditorLogin: async function (){
            log("Auditor login")
            var self = this
            self.showError = false
            self.loading = true

            var validation = []
            var email = self.loginData.email
            var password = self.loginData.password

            if (email == '' || email.length <= 0) validation.push(false)
            if (password == '' || password.length <= 0) validation.push(false)
    
            if (validation.length === 0) {
                if (this.hasConnection) {
                    this.$overlay.loading()
                    console.warn('init syncronize')

                    try {
                        await this.$store.dispatch('syncronize')
                        console.warn('end syncronize')
                        self.$store
                            .dispatch('login/doLoginAuditor', {
                                email: email,
                                password: sha256(password)
                            })
                            .then(function (response) {
                                if(response){
                                    log('doLogin3')
                                    
                                    const home = self.$store.getters['getHome']
                                    self.$store.commit('setAuditorLogin', true)
                                    self.$overlay.hide()
                                    self.$router.push(self.$root.redirectPath(!home ? '/audit' : home))
                                }else{
                                    self.showError = true
                                    self.errorMessage = self.$t('login.errors.auditor_access')
                                    self.loading = false
                                    self.$overlay.hide()
                                }
                            })
                    }catch(error){
                        self.showError = true
                        self.errorMessage = self.$t('login.errors.auditor_access')
                        self.loading = false
                        self.$overlay.hide()
                    }
                } else {
                    self.showError = true
                    self.errorMessage = self.$t('login.errors.offline')
                    self.loading = false
                    self.$overlay.hide()
                }
            } else {
                self.showError = true
                self.errorMessage = self.$t('login.errors.input')
                self.loading = false
            }
        },
        goBack() {
            this.pwForgot.active = false
            this.passwordCode = false
            this.passwordNew = false
            this.pwReset.active = false
            this.pwCode.active = false
        },
        gotoHelpcenter() {
            if (this.isApp) {
                cordova.InAppBrowser.open('https://help.andyapp.io/portal/es/newticket', '_system')
            } else {
                window.open('https://help.andyapp.io/portal/es/newticket', '_blank')
            }
        },

        gotoForgotPass() {
            this.pwForgot.active = true
            this.pwForgot.error.validate = false
            this.pwForgot.error.mail = false
            this.pwForgot.input = this.loginData.location ? this.loginData.location : ''
        },
        pwForgotPass() {
            if (this.hasConnection && !this.loading) {
                var self = this
                var input = self.pwForgot.input

                self.loading = true
                self.pwForgot.error.validate = false
                self.pwForgot.error.mail = false

                if ((input != '' && input.length > 3) || self.$root.validateEmail(input)) {
                    self.$store.dispatch('login/sendForgotMail', { identification: input }).then(function (response) {
                        if (response.status) {
                            self.pwForgot.location_id = response.data.location_id
                            self.pwForgot.location_email = response.data.email
                            self.gotoSetCode()
                        } else {
                            if (response.error && response.error.code == -2) {
                                self.pwForgot.error.mail = true
                            }
                        }
                        self.loading = false
                    })
                } else {
                    self.pwForgot.error.validate = true
                    self.loading = false
                }
            }
        },

        gotoSetCode() {
            this.pwForgot.active = false

            this.pwCode.active = true
            this.pwCode.success.resend = false
            this.pwCode.error.validate = false
            this.pwCode.error.resend = false
            this.pwCode.error.invalid = false
            this.pwCode.input = ''
        },
        truncateCode() {
            let input = this.pwCode.input

            if (input.length > 6) {
                this.pwCode.input = input.slice(0, 6)
            }
        },
        resendCode() {
            if (this.hasConnection && !this.loading) {
                var self = this
                self.pwCode.success.resend = false
                self.pwCode.error.resend = false
                self.pwCode.error.validate = false
                self.pwCode.error.invalid = false

                if (!self.pwCode.resended) {
                    self.$store.dispatch('login/resendCode', { identification: self.pwForgot.input }).then(function (response) {
                        self.pwCode.success.resend = true
                        self.pwCode.resended = true
                        self.pwCode.input = ''

                        setTimeout(function () {
                            self.pwCode.resended = false
                            self.pwCode.success.resend = false
                        }, 120000)
                    })
                } else {
                    self.pwCode.error.resend = true

                    setTimeout(function () {
                        self.pwCode.error.resend = false
                    }, 5000)
                }
            }
        },
        pwSetCode() {
            if (this.hasConnection && !this.loading) {
                var self = this
                var input = self.pwCode.input

                self.pwCode.error.validate = false
                self.pwCode.error.resend = false
                self.pwCode.error.invalid = false
                self.loading = true

                if (input.length == 6 && !isNaN(input)) {
                    self.$store.dispatch('login/sendCode', { location_id: self.pwForgot.location_id, code: input }).then(function (response) {
                        if (response.status) {
                            self.gotoResetPass()
                        } else {
                            self.pwCode.error.invalid = true
                        }
                    })
                    self.loading = false
                } else {
                    self.pwCode.error.validate = true
                    self.loading = false
                }
            }
        },

        gotoResetPass() {
            this.pwCode.active = false

            this.pwReset.active = true
            this.pwReset.error.format = false
            this.pwReset.error.samePass = false
            this.pwReset.error.isNotNumber = false
            this.pwReset.password = ''
            this.pwReset.passwordConfirm = ''
        },
        invalidFormat() {
            var self = this
            let input = self.pwReset.password
            let hasErrors = false

            if (input != '' && input.length >= 8) {
                hasErrors = false
            } else {
                hasErrors = true
            }

            return hasErrors
        },
        equalPass() {
            var self = this
            let input = self.pwReset.password
            let inputConfirm = self.pwReset.passwordConfirm
            let isEqual = false

            if (input === inputConfirm) {
                isEqual = true
            }

            return isEqual
        },

        checkOnlyNumbers() {
            var self = this
            let input = self.pwReset.password
            let inputConfirm = self.pwReset.passwordConfirm
            let isNumber = false

            if (!isNaN(input) && !isNaN(inputConfirm)) {
                isNumber = true
            }

            return isNumber
        },

        checkPass() {
            var self = this
            let input = self.pwReset.password
            let inputConfirm = self.pwReset.passwordConfirm
            let format = false
            let samePass = false
            let isNotNumber = false

            if (inputConfirm.length >= 8) {
                if (self.invalidFormat()) {
                    format = true
                }

                if (!self.invalidFormat() && !self.equalPass()) {
                    samePass = true
                }

                if (input.length == '' && inputConfirm.length == '') {
                    format = false
                    samePass = false
                }

                if (!self.checkOnlyNumbers()) {
                    isNotNumber = true
                }
            }

            self.pwReset.error.format = format
            self.pwReset.error.samePass = samePass
            self.pwReset.error.isNotNumber = isNotNumber
        },
        pwResetPassword() {
            if (this.hasConnection && !this.loading) {
                var self = this
                var input = self.pwReset.password
                let inputConfirm = self.pwReset.passwordConfirm

                self.pwReset.error.format = false
                self.pwReset.error.samePass = false
                self.loading = true

                if (!self.invalidFormat() && self.equalPass() && self.checkOnlyNumbers() && input != '' && inputConfirm != '') {
                    self.$store
                        .dispatch('login/resetPassword', { location_id: self.pwForgot.location_id, password: input, hashPassword: sha256(input) })
                        .then(function (response) {
                            localforage.config({
                                name: 'alexdb',
                                version: 1.0
                            })
                            var db_products = localforage.createInstance({
                                name: 'alexdb',
                                storeName: 'products'
                            })
                            db_products.clear()
                            var db_product_categories = localforage.createInstance({
                                name: 'alexdb',
                                storeName: 'product_categories'
                            })
                            db_product_categories.clear()
                            var db_employee = localforage.createInstance({
                                name: 'alexdb',
                                storeName: 'employee'
                            })
                            db_employee.clear()

                            localforage
                                .clear()
                                .then(function () {
                                    // console.log('Database is now empty.')

                                    // self.$store.dispatch( 'checkChangesPrint', response.data.printer );
                                    const config = self.$store.getters['login/getConfig']

                                    var path = config && config.home ? config.home : false
                                    // console.log('PATH', path)
                                    self.$store.commit('setCustomHome', path)

                                    self.$store
                                        .dispatch('employee/loadEmployees')
                                        .then(function () {
                                            // console.log(self.$store.getters['employee/getAllEmployees'])
                                            self.$store.dispatch('employee/loadRoles').then(function () {})
                                            //   self.$store.dispatch("setPrinterModels");
                                        })
                                        .then(function () {
                                            self.$router.push(self.$root.redirectPath(!path ? '/home' : path))
                                        })
                                })
                                .catch(function (err) {
                                    log(err)
                                })
                        })
                        .catch((error) => {
                            self.showError = true
                            self.errorMessage = self.$t('login.errors.offline')
                            self.loading = false
                        })
                } else {
                    self.checkPass()
                    if (!self.pwReset.error.format && !self.pwReset.error.samePass) {
                        self.pwReset.error.format = true
                    }
                    self.loading = false
                }
            }
        },

        openTicketPopup() {
            if (this.isApp) {
                cordova.InAppBrowser.open('https://help.andyapp.io/', '_system')
            } else {
                window.open('https://help.andyapp.io/', '_blank')
            }
            // let self = this

            // this.$popup.ticket({
            //     callSave: function() {
            //         // close popup
            //         self.$popup.close()

            //         // show tooltip
            //         self.$snackbar.success({ message: self.$t('tickets.success'), duration: 5000, closeable: true })
            //     }
            // })
        },
        async loadStoredLocations() {
            this.$overlay.loading()
            this.storedLocations = await this.$store.dispatch('login/getStoredSites')
            this.$overlay.hide()
            if (this.storedLocations.length == 0) {
                this.normalLogin = true
            }
        },
        async removeStoredSite(location) {
            this.$overlay.loading()
            await this.$store.dispatch('login/removeStoredSite', location)
            this.$overlay.hide()
            this.loadStoredLocations()
        },
        forgotPassSubmit() {
            if (this.forgotEmailSubmit != '') {
                this.emptyEmail = false
            } else {
                this.emptyEmail = true
            }

            if (!this.emptyEmail) {
                let self = this
                self.$overlay.loading()
                this.$store
                    .dispatch('login/emailForgot', {
                        email: this.forgotEmailSubmit,
                        lang: typeof localStorage.language !== 'undefined' ? localStorage.language : 'es'
                    })
                    .then(function (response) {
                        if (!response.status) {
                            self.errorMail = true
                            self.emailSend = false
                        } else {
                            self.errorMail = false
                            self.emailSend = true
                        }
                        self.$overlay.hide()
                    })
            }
        },
    },
    watch: {
        '$i18n.locale'() {
            this.$nextTick(() => {
                this.languageChangedKey++;
            });
        }
    },
    created() {
        this.$store.commit('login/logout')
        this.$store.dispatch('resetStore')

        // const db = localforage.createInstance({ name: 'alexdb' })
        // db.clear()
        this.loadStoredLocations()
    },
    mounted() {
        document.getElementById('fullcontent').className = 'inLogin'
    },
    updated() {
        if (this.$store.getters.getIsApp) {
            document.getElementsByClassName('link-terms').forEach((e) => {
                var link = e.href
                e.onclick = function () {
                    cordova.InAppBrowser.open(link, '_blank')
                    return false
                }
                e.href = ''
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#login.login-organization {
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items();

    height: 100%;
    width: 100%;
    min-width: 100px;
    overflow: auto;
    padding: 15px 30px;
    // overflow: hidden;

    .login-top,
    .login-bottom {
        @include display-flex();
        @include flex-direction(row);
        @include align-items();
        @include justify-content(space-between);
        @include flex-wrap(wrap);
        height: 40px;
        width: 100%;
    }
    .login-top {
        margin-bottom: 6px;

        .help {
            @include background($image: img('menu_help_dark.svg'), $size: 30px);
            width: 40px;
            height: 40px;
            cursor: pointer;
        }
        .lang {
            @include background($image: img('lang_neutro_s70.svg'), $position: left center, $size: 22px);
            padding-left: 20px;
            background-color: transparent;
            width: 80px;
            height: 40px;

            // .btn {
            //     @include font-size(sm);
            //     text-transform: uppercase;
            //     text-align: center;
            //     color: $main-dark;
            //     background-color: transparent;
            //     padding: 0;
            //     width: rem(35px);
            //     height: rem(30px);
            //     margin: rem(5px);

            //     &.selected {
            //         background-color: rgba($main, 0.2);
            //     }
            //     &:hover {
            //         background-color: rgba($main, 0.1);
            //     }
            // }

            select {
                @include background($color: #ffffff, $image: img('down_caret_neutro_s90.svg'), $size: 8px 8px, $position: center right);
                @include font-size(m);
                -webkit-appearance: none;
                -moz-appearance: none;
                border: none;
                background-color: transparent;
                font-family: $text;
                width: 100%;
                height: 40px;
                margin: 0;
                padding: 8px 5px 5px 8px;
                cursor: pointer;
                padding-left: 10px;
                text-transform: uppercase;
                color: $neutro-s70;
            }

            option {
                @include font-size(m);
                font-family: $text;
            }
        }
    }
    .login-bottom {
        margin-top: 6px;

        .version {
            @include font-size(s);
            font-family: $text-light;
            color: $neutro-s50 !important;
            display: inline-block;
            width: auto;
        }
    }

    .login-container {
        @include display-flex();
        max-height: calc(100% - 40px - 40px);

        &.wide {
            .login-form {
                width: 100%;

                .form {
                    max-width: 500px;
                    .form-description {
                        margin-bottom: 20px;
                    }
                }
            }
        }
        &.forgot-password {
        }
        &.set-code {
            .login-form {
                .form {
                    .input-container {
                        .input {
                            @include font-size(xl);
                            height: 80px;
                            width: 100%;
                            // max-width: 250px;
                            text-align: center;
                            letter-spacing: 15px;
                        }
                    }
                }
            }
        }

        .login-gallery {
            @include background($image: img('ellipse_ffffff.svg'));
            @include display-flex();
            @include justify-content();
            @include align-items();
            @include flex-direction(column);
            width: 400px;
            padding: 60px;
            margin: 15px 30px 15px 60px;

            .gallery-image {
                @include background($image: img('alex_carita_12.svg'));
                min-width: 125px;
                min-height: 100px;
            }
            .gallery-info {
                margin-top: 9px;

                .titl,
                .description {
                    display: block;
                    width: 100%;
                }

                .titl {
                    @include font-size(l);
                    font-family: $text-bold;
                    color: $main-s30;
                    margin-bottom: 10px;
                }
                .description {
                    @include font-size(sm);
                    font-family: $text;
                    color: $main-s30;
                }
            }
        }

        .login-form {
            @include display-flex();
            @include justify-content();
            @include flex-direction(column);
            width: 350px;
            padding: 15px 30px;
            margin: 15px 30px;

            .logo {
                @include background($image: img('logo_andy_small.png'), $position: left center);
                width: 100%;
                height: 30px;
                margin-bottom: 30px;
                min-height: 30px;
            }

            .switch-logins{
                @include display-flex();
                @include justify-content(space-around);
                @include align-items();
                @include flex-direction(row);
                background: $color-white;
                @include border-radius(4px);
                width: 100%;
                padding: 4px;
                color: $main;
                transition: all 0.3s ease-in-out;

                .btn {
                    background: transparent;
                    color: $neutro-s50;
                    font-weight: 500;
                    @include font-size();
                    font-family: Avenir-Regular;
                    position: relative;
                    overflow: hidden;
                    z-index: 1;

                    &:nth-child(1) {
                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 100%;
                            width: 100%;
                            height: 100%;
                            background: $main-t80;
                            border-radius: 4px;
                            z-index: -1;
                            transition: all 0.3s ease-in-out;
                        }
                        &.selected{
                            &::before {
                                left: 0;
                            }
                        }
                    }

                    &:nth-child(2) {
                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: -100%;
                            width: 100%;
                            height: 100%;
                            background: $main-t80;
                            border-radius: 4px;
                            z-index: -1;
                            transition: all 0.3s ease-in-out;
                        }
                        &.selected{
                            &::before {
                                left: 0;
                            }
                        }
                    }

                    &.selected {
                        font-family: Avenir-Bold;
                        color: $main;
                    }
                }
            }

            .login-return {
                @include interaction();
                @include background($image: img('left_arrow_inactive_s50.svg'), $position: left center, $size: 18px);
                @include font-size(sm);
                font-family: $text-medium;
                color: $inactive-s50;
                width: 100%;
                padding-left: 24px;
                margin-bottom: 30px;
            }

            .form {
                .auditor-messages {
                    .error-message {
                        color: $color-white;
                        background-color: $color-error-500 
                    }

                    .success-message {
                        color: $color-white;
                        background-color: $color-success-500 
                    }
                }

                .titl {
                    @include font-size(l);
                    font-family: $text-bold;
                    color: $inactive-s90;
                    margin-bottom: 9px;
                }

                .document {
                    margin-bottom: 20px;
                }

                .form-description {
                    @include font-size(m);
                    font-family: $text-medium;
                    color: $inactive-s70;

                    b,
                    strong {
                        font-size: inherit;
                        color: inherit;
                        font-family: $text-bold;
                    }
                }

                .input-container {
                    margin: 0;
                    margin-top: 15px;

                    .label,
                    .input {
                        @include font-size(m);
                        color: $neutro-s90;

                        display: block;
                        width: 100%;
                        margin: 0;
                    }

                    .label {
                        font-family: $text-bold;
                        padding: 0;
                        margin-bottom: 3px;
                    }
                    .input {
                        @include border-radius(3px);
                        font-family: $text;
                        border: none;
                        height: 45px;
                        width: 100%;
                        padding: 9px 12px;

                        &::placeholder {
                            @include font-size(m);
                        }
                    }
                }
            }

            .terms-conditions {
                @include font-size(s);
                font-family: $text-sbold;
                color: $main-s30;
                padding-top: 20px;
                display: inline-block;

                b {
                    font-family: $text-sbold;
                    @include interaction();
                    text-decoration: underline;
                    color: $main-dark;
                    display: inline-block;
                }
            }

            .lost-password,
            .resend-code {
                @include interaction();
                @include font-size(s);
                font-family: $text-bold;
                color: $main-s30;
                text-decoration: underline;
                margin-top: 30px;

                &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed !important;
                }

                &.landscape {
                    margin-top: 10px;
                }
            }

            .submit-login {
                @include font-size(ml);
                margin-top: 30px;
                min-height: 50px;
                height: auto;
                width: 100%;
                max-width: 300px;

                &.loading {
                    @include background($image: img('loader_light.svg'), $position: center center, $size: 40px);
                    pointer-events: none;
                    text-indent: -99999px;
                    animation: none;
                }
            }
        }

        .error-message,
        .success-message,
        .info-message {
            @include border-radius(3px);
            @include font-size(s);
            font-family: $text-bold;
            color: $inactive-s50;
            background-color: $inactive-t50;
            padding: 6px 9px;
            margin-top: 15px;
        }
        .error-message {
            color: $error-s50;
            background-color: $error-t50;

            u {
                text-decoration: underline !important;
                font-family: $text-bold !important;
                color: $error-s50 !important;
                cursor: pointer;
            }
        }
        .success-message {
            color: $done-s50;
            background-color: $done-t50;
        }
        .info-message {
            color: $info-s50;
            background-color: $info-t50;
        }
    }

    @media (max-width: 900px) {
        padding: 9px 12px;

        .login-container {
            @include flex-direction(column);
            @include align-items();

            .login-gallery,
            .login-form {
                width: 100%;
            }

            .login-gallery {
                @include flex-direction(row);
                padding: 15px 30px;
                margin: 0;
                // background-image: none;
                // background-image: img('ellipse_horizontal_ffffff.svg');

                .gallery-image {
                    min-width: 80px;
                    min-height: 80px;
                }
                .gallery-info {
                    margin: 0;
                    margin-left: 12px;

                    .titl {
                        @include font-size(m);
                    }
                    .description {
                        @include font-size(s);
                    }
                }
            }

            .login-form {
                @include flex-direction(column);
                @include align-items();

                padding: 0 30px;
                margin: 15px 0;

                .logo {
                    display: none;
                }

                .form {
                    max-width: 400px;
                }
            }
        }
    }

    .multiaccount-login {
        width: 100%;
        height: calc(100% - 46px);
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
#login.login-organization.portrait {
    @include justify-content(flex-start);
    height: 100%;
    max-height: 100%;
    min-height: 100%;

    // GENERAL STYLES
    .lang {
        @include display-flex();
        @include justify-content();
        @include align-items();

        select {
            @include font-size(sm, $important: true);
            padding-top: 9px !important;
        }
    }

    input {
        @include font-size(sm, $important: true);
        width: 100% !important;
        min-width: 100%;
        border: 1px solid $neutro !important;
        text-align: center;
        height: 55px !important;

        &::-webkit-input-placeholder {
            @include font-size(s, $important: true);
        }
    }

    .login-gallery,
    .login-form {
        width: 100%;

        .switch-logins{
            width: 100%;
            margin-bottom: 16px;

            .btn{
                width: 100%;
            }
        }
    }

    .login-container {
        @include flex-direction(column);
        @include align-items();
        @include background($image: img('ellipse_ffffff.svg'), $position: 50% 40%, $size: 300px);
        @include justify-content();

        // max-height: fit-content;
        height: calc(100%);

        .logo {
            @include background($image: img('logo_andy_small.png'), $position: top center, $size: 80px);
            width: 100%;
            height: 35px;
            min-height: 35px;
            position: relative;
            top: -3vh;
        }

        .login-gallery {
            @include flex-direction(column);
            padding: 15px 30px 0;
            margin: 0;
            background-image: none;
            // background-image: img('ellipse_horizontal_ffffff.svg');

            .gallery-image {
                min-width: 80px;
                min-height: 80px;
            }
            .gallery-info {
                margin: 0;

                .titl {
                    @include font-size(m);
                }
                .description {
                    @include font-size(s);
                }
            }
            background-image: unset;

            .titl {
                text-align: center;
                margin-top: 15px;
            }
        }

        .login-form {
            @include flex-direction(column);
            @include align-items();
            max-width: 400px;
            margin: 0 auto;
            padding: 0 10px;
            margin: 15px 0;
            // height: 100%;

            .logo {
                display: none;
            }

            .form {
                width: 100%;

                .titl {
                    @include font-size(ml);
                    color: $main-dark;
                }

                .form-description {
                    @include font-size(s);
                    margin-bottom: 30px;
                }

                .input-container,
                .error-message,
                .success-message {
                    margin-top: 0;
                    margin-bottom: 9px;
                }
            }

            .submit-login {
                @include font-size(sm);
                margin-top: 0;
                max-width: unset;
                height: 55px;
            }

            .lost-password {
                color: $main;
                font-family: $text;
            }

            .terms-conditions {
                @include font-size(xs);
                font-family: $text-sbold;
                color: $main-s30;
                padding-top: 20px;
                display: inline-block;

                b {
                    @include font-size(xs);
                    @include interaction();
                    text-decoration: underline;
                    color: $main-dark;
                    font-family: $text-sbold;
                    display: inline-block;
                }
            }
        }
    }
    .login-bottom {
        margin-top: 50px;
        width: 100%;
        min-height: 40px;

        .version {
            @include font-size(xs);
            line-height: 18px;
            max-width: 50%;
            height: auto;
            margin-bottom: 6px;
        }
    }

    // FORGOT PASSWORD
    .forgot-password {
        .login-form {
            height: auto;
            .form {
                .titl {
                    @include font-size(ml);
                    color: $main-dark;
                }

                .form-description {
                    @include font-size(s);
                    margin-bottom: 30px;
                }
            }

            .return-login {
                @include interaction();
                @include font-size(s);
                font-family: $text-bold;
                color: $main-s30;
                text-decoration: underline;
                margin-top: 30px;
                color: $main;
                font-family: $text;
            }
        }
    }

    // SET CODE
    .set-code {
        height: 100%;

        .login-form {
            height: 100%;
            .form {
                .titl {
                    @include font-size(ml);
                    color: $main-dark;
                }

                .form-description {
                    @include font-size(s);
                    margin-bottom: 30px;
                }

                .input {
                    height: 50px !important;

                    // remove arrow
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    &[type='number'] {
                        -moz-appearance: textfield;
                    }
                }
            }

            .resend-code {
                @include interaction();
                @include font-size(s);
                font-family: $text-bold;
                color: $main-s30;
                text-decoration: underline;
                margin-top: 30px;
                color: $main;
                font-family: $text;
            }
        }
    }

    // RESET PASSWORD
    .reset-password {
        height: 100%;
    }
}
</style>
